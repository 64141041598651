.buttons{
 background-color: $color-2;
 color: $white;
 font-size: 20px;
 padding: 8px 20px 8px 20px;
 border: none;
 margin-top: 50px;
 transition: 0.3s;

 &:hover{
    background-color: $color-1;
}

}

