.footer {
  
    height: 352px;
    font-size: 18px;
    padding: 4% 25%;
    display: flex;
    
    li {
        font-size: 13px;
        list-style: none;
    }

    .footer_contact{
        margin: 0 70px 70px 70px;

    }
}

@media all and (max-width:576px){

    .footer {
        display: block;
        height: 352px;
        font-size: 18px;
        padding: 4% 10%;
        text-align: center;

    
    
        .footer_contact{
            margin: 18px 0px 18px 0px;
    
        }
    }
 }