.header{


        padding-top: 15px;
        background-color: $color-1;
        text-align: center;
        height: 46px;
    
    .Titre {
        font-size: 20px;
        text-align: center;
        position: relative;
        transform: translateY(250%);
        
    }

    
}


@media all and (max-width:576px){
    .header{

    .Titre {
        font-size: 20px;
        transform: translateY(140%);
        
    }

    
}


 }
 
 
