* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body,html{
    height: 100%;
    width: 100%;
    background-color: $color-1;

    
}

#root {
height: 100%;
width: 100%;
}