.grid {
    /*position: absolute;
    top: 500px;
    left: 460px;
  
    */
    transform: translateY(-30%);
    width: 1000px;
    margin:  0 auto;
    height: 1200px;
    display: grid;
    grid-template-columns: 1fr 1fr  ;
    grid-template-areas: 
    "a b"
    "c b"
    "c d"
    "e d"
    "e f"
    "g f";
    

    .image {
        min-height: 319px;
        margin: 11px;
       
       
    }

    .image:nth-child(1) {
        background-image: url('https://static.wixstatic.com/media/33d1d1_2f8b820a0e0944849f7ecdf6f4b120d7~mv2.jpg/v1/fill/w_479,h_319,al_c,q_80,usm_0.66_1.00_0.01/33d1d1_2f8b820a0e0944849f7ecdf6f4b120d7~mv2.jpg');
        grid-area: a;
        box-shadow: 1.77px 0.94px 3px 0px rgba(0, 0, 0, 0.363);
    }
    .image:nth-child(2) {
        background-image: url("https://static.wixstatic.com/media/33d1d1_3f3ae32c5ea249a5be609f52a83446f1~mv2.jpg/v1/fill/w_479,h_600,al_c,q_80,usm_0.66_1.00_0.01/33d1d1_3f3ae32c5ea249a5be609f52a83446f1~mv2.jpg");
        grid-area: b;
        box-shadow: 1.77px 0.94px 3px 0px rgba(0, 0, 0, 0.363);

        
    }
    .image:nth-child(3) {
        background-image: url("https://static.wixstatic.com/media/33d1d1_9653c86109d44db9b223943b304c2066~mv2.jpg/v1/fill/w_479,h_393,al_c,q_80,usm_0.66_1.00_0.01/33d1d1_9653c86109d44db9b223943b304c2066~mv2.jpg");
        grid-area: c;
        background-size: cover;
        box-shadow: 1.77px 0.94px 3px 0px rgba(0, 0, 0, 0.363);

    }
    .image:nth-child(4) {
        background-image: url("https://static.wixstatic.com/media/33d1d1_c02cf72a1b254b9792319f662d586708~mv2_d_2310_1540_s_2.jpg/v1/fill/w_479,h_319,al_c,q_80,usm_0.66_1.00_0.01/33d1d1_c02cf72a1b254b9792319f662d586708~mv2_d_2310_1540_s_2.jpg");
        grid-area: d;
        box-shadow: 1.77px 0.94px 3px 0px rgba(0, 0, 0, 0.363);
    }
    .image:nth-child(5) {
        background-image: url('https://static.wixstatic.com/media/33d1d1_0c9a2b6dd1d649e590e9cb68cc56c2e4~mv2_d_2019_1449_s_2.jpg/v1/fill/w_479,h_343,al_c,q_80,usm_0.66_1.00_0.01/33d1d1_0c9a2b6dd1d649e590e9cb68cc56c2e4~mv2_d_2019_1449_s_2.jpg');
        grid-area: e;
        box-shadow: 1.77px 0.94px 3px 0px rgba(0, 0, 0, 0.363);
    }
    .image:nth-child(6) {
        background-image: url('https://static.wixstatic.com/media/33d1d1_5ea4ee8e375147ba9fe54df41210dc8b~mv2_d_1417_1604_s_2.jpg/v1/fill/w_479,h_542,al_c,q_80,usm_0.66_1.00_0.01/33d1d1_5ea4ee8e375147ba9fe54df41210dc8b~mv2_d_1417_1604_s_2.jpg');
        grid-area: f;
        box-shadow: 1.77px 0.94px 3px 0px rgba(0, 0, 0, 0.363);
    }


}


@media all and (max-width:1200px){

}


@media all and (max-width:1024px){
   
}




@media all and (max-width:992px){
    .grid {
      
        width: 600px;

        .image {
            min-height: 100px;
           background-size: auto;
           
           
        }
        
  
}
}
@media all and (max-width:768px){
    .grid {
        height: 1200px;
        width: 700px;


        

        .image {
            min-height: 100px;
           background-size: auto;
           
           
        }
        
  
}


}

@media all and (max-width:576px){
    .grid {
        transform: translateY(-2%);
        width: 350px;
        height: 2500px;

        grid-template-columns: 1fr ;
        grid-template-areas: 
        "a"
        "b"
        "c"
        "d"
        "e"
        "f"
        "g";
        

        .image {
            min-height: 100px;
           background-size: cover !important;
           height: 400px !important;
           
           
        }
        
  
}

}
