@font-face {
    font-family: fontfamily;
    src:('/public/font/5cee8d6e-89ad-4d8c-a0ac-584d316b15ae.woff2');
}

@font-face {

    font-family:Belinda;
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/63d38753-a8d9-4262-b844-3a007ad848b4.eot?#iefix");
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/63d38753-a8d9-4262-b844-3a007ad848b4.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/242487aa-209a-4dbd-aca2-64a3c73a8946.woff2") format("woff2"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/4381b252-d9f8-4201-bbf3-9440e21259e7.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/46c2c4f2-cd15-4b7b-a4b4-aa04dbbd1655.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/334f8999-3703-47f3-b147-96b6dc3bf4b2.svg#334f8999-3703-47f3-b147-96b6dc3bf4b2") format("svg");
}


body {
    font-size:12px;
    font-family: fontfamily,sans-serif;
    font-weight: 800;
    color: $white;

   }
   input,
   select,
   textarea {
    box-sizing:border-box
   }

a {
    text-decoration: none;
}
   