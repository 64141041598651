.textButtons {   
    flex: 1;
    color: #0d3f4f;
    background-color: #ffffff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    .text_Buttons_div {
        padding: 0px 10px 0px 10px;
    }
    h2 {
        letter-spacing: 0.05em;
        font-size: 30px;
        margin-bottom: 30px;
        
    }

    span {
        font-size: 20px;
font-weight: 600;
    }
}

@media all and (max-width:960px){
     h2 {
         letter-spacing: 0.05em;
         font-size: 30px;
         margin-bottom: 20px;
         
     }
     span {
         font-size: 20px;
 font-weight: 600;
     }
   
 }

 @media all and (max-width:960px){
    .textButtons {
        padding : 10px 10px 10px 10px;
}
}
