.compoMusique {

    height: 600px;
    background-image: url("https://static.wixstatic.com/media/33d1d1_807a57f2bddb4b718af111169210cb43~mv2.jpg/v1/fill/w_1903,h_675,al_c,q_85,usm_0.66_1.00_0.01/33d1d1_807a57f2bddb4b718af111169210cb43~mv2.webp");
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    align-items: center;
    justify-content: center;

    &_text{
       position: relative;
      text-align: center;
       
    }

    span {
        font-size: 38px;
        font-weight: 400;
        line-height: 3;
    }

    h2 {
        font-size: 54px;
        line-height: 1.4;
        font-weight: 800;
        letter-spacing: 0.3rem;
    }

}



@media all and (max-width:960px){

    .compoMusique {
        height: 400px;
    
        h2{
            font-size: 28px;
        
        }
        span {
            font-size: 20px;
            font-weight: 400;
            line-height: 3;
        }
    }
 }
