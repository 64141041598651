.communication {

    height: 600px;
    background-image: url("https://static.wixstatic.com/media/33d1d1_625b765c42584f1ba281e4784cf2ac4c~mv2_d_1824_1216_s_2.jpg/v1/fill/w_1824,h_748,al_l,q_85/33d1d1_625b765c42584f1ba281e4784cf2ac4c~mv2_d_1824_1216_s_2.webp");
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    align-items: center;
    justify-content: center;

    &_text{
       position: relative;
      text-align: center;
       
    }

    span {
        font-size: 38px;
        font-weight: 400;
        line-height: 3;
    }

    h2 {
        font-size: 54px;
        line-height: 1.4;
        font-weight: 800;
        letter-spacing: 0.3rem;
    }

    

    Button {
        background-color: rgba(255, 255, 255, 0);
        border : 2px solid $white;

        &:hover {
        background-color: #2f2e2e;
        }
    }
    
}



@media all and (max-width:960px){

    .communication {
        height: 400px;
        background-size: 1628px 1100px;
     
        h2{
            font-size: 30px;
        
        }
        span {
            font-size: 20px;
            font-weight: 400;
            line-height: 3;
        }
    }
 }