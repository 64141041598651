.titrepage {

    height: 347px;
    text-align: center;

    .bigstyle {
        position: relative;
        top: 30%;
        font-size: 10em;
        letter-spacing: 1rem;

        
    }

    .littlestyle {
        position: relative;
        top: 30%;
        font-size: 25px;
        color: $color-2;
       font-family: Belinda;
       font-weight: 400;
        
    }



}

@media all and (max-width:476px){
    .titrepage {

        height: 347px;
        text-align: center;
    
        .bigstyle {
            position: relative;
            top: 30%;
            font-size: 5em;
            letter-spacing: 1rem;
    
            
        }
    
        .littlestyle {
            position: relative;
            top: 30%;
            font-size: 25px;
            color: $color-2;
           font-family: Belinda;
           font-weight: 400;
            
        }
    
    
    
    }

}