.imageMenu {
    flex: 1;
    background-size: cover;
    background-image: url('https://static.wixstatic.com/media/33d1d1_3c19d588c1b146dda999cceef290d5b0~mv2.jpg/v1/fill/w_1060,h_1225,al_c,q_85/fond2.webp');

}

.imagecreas {
    flex: 1;
    background-size: cover;
    background-image: url("https://static.wixstatic.com/media/33d1d1_49b080576b324e208ecdfacbcf9c522d~mv2.jpg/v1/crop/x_0,y_50,w_1000,h_650,q_85/33d1d1_49b080576b324e208ecdfacbcf9c522d~mv2.webp");
}
    .imagenuancier {
        flex:1;
        background-size: cover;
        background-image: url("https://static.wixstatic.com/media/33d1d1_d36486154c62433d8f90362618eef977~mv2.jpg/v1/fill/w_1542,h_650,al_c,q_85/pantone.webp");
        background-position-x : +100%; 
    }
    

.imagework {
        flex : 1;
        background-size: cover;
        background-image: url("https://static.wixstatic.com/media/33d1d1_3c19d588c1b146dda999cceef290d5b0~mv2.jpg/v1/fill/w_1903,h_509,al_c,q_85,usm_0.66_1.00_0.01/33d1d1_3c19d588c1b146dda999cceef290d5b0~mv2.webp");
        background-position-x : +100%; 
    }




@media all and (max-width:960px){
  
}

@media all and (max-width:476px){
    .imageMenu {
        display: none;
 
 }
    .imagecreas {
        display: none;

}

    .imagenuancier {
        display: none;
}

.imagework {
   display: none;
}

 }

