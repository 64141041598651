.Accueil {
  
background-image: url("https://static.wixstatic.com/media/33d1d1_cae60c5af5d24c8cb0356da56635da8f~mv2_d_6000_4000_s_4_2.jpg/v1/fill/w_1903,h_770,al_c,q_85,usm_0.66_1.00_0.01/33d1d1_cae60c5af5d24c8cb0356da56635da8f~mv2_d_6000_4000_s_4_2.webp");
background-size: cover ;
width: 100%;
height: inherit;


.header { 
    padding: 19px;
    background-color: $color-1;
    text-align: center;
}

.Titre {
    margin-top: 1%;
    font-size: 10px;
    text-align: center;
    
}
.KeepCalm {
    font-size: 50px;
    font-weight: 600;
    padding-left: 13%;
    padding-top: 13% ;
}

}

@media screen and (max-width: 1200px) {
    .Accueil {
        height: 500px
    }
}


 @media all and (max-width:960px){
     .Accueil {
        height: 400px;
    .KeepCalm {
        font-size: 25px;
        padding-left: 8%;
        padding-top: 20% ;
    
    }
}
 }


 @media all and (max-width:768px){


    .KeepCalm {
        font-size: 33px;
        padding-left: 20%;
        padding-top: 20% ;
    
    }

 }


 @media all and (max-width:576px){
    .Accueil {
        height: 230px;
        .Titre {
           padding-top: 15%;
            font-size: 20px;
            text-align: center;
            
        }
    .KeepCalm {
        font-size: 25px;
        padding-left: 8%;
        padding-top: 23% ;
    
    }
}
 }
 
 
