.navigation {
    margin: 80px 2% 0;
    display: flex;
    border-left: 1px;
    width: 600px;
    position: fixed;
    z-index: 1;
    
    
a:not(:first-child):before {
        content: "|";
        float: left;
        width: 1px;
        display: block;
        line-height: 25px;
        background: $color-2;
        color : rgba(255, 255, 255, 0);
        margin-right: 15px;
    }

    a {

        padding: 10px;
        border-radius: 4px;
        position: relative;
        line-height: 25px;
        color : $white; 
        font-size: 13px;
        font-weight: 600;
        transition: 0.3s;
        
    }

    a:hover {
       color : $color-3;
    }

    .active {

        color: $color-2;

    }



}

@media  screen and (max-width: 767px) {
    .navigation {
       display: none;
    }
    
}