.home{
    height: inherit;

    .divflex {
        display: flex;
        height: 651px;
    }
}


@media all and (max-width:960px){
    .home{
        height: inherit;
    
        .divflex {
            display: flex;
            height: 500px;
        }
    }
    
}

@media all and (max-width:476px){
    .home{
        height: inherit;
    
        .divflex {
            display: flex;
            height: 400px;
        }
    }
    
 }

